<!-- =========================================================================================
  File Name: VerticalNavMenu.vue
  Description: Vertical NavMenu Component
  Component Name: VerticalNavMenu
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="parentx" v-if="!subscriptionCancelled">
    <vs-sidebar
      class="v-nav-menu items-no-padding"
      v-model="isVerticalNavMenuActive"
      ref="verticalNavMenu"
      default-index="-1"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      v-hammer:swipe="onMenuSwipe"
    >
      <div
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
        :class="{
          _locked: lockedUI
        }"
      >
        <!-- Header -->
        <div class="header-sidebar flex items-end justify-between" slot="header">
          <!-- Logo -->
          <router-link class="vx-logo cursor-pointer flex items-center" to="/" custom>
            <template v-slot="{ navigate, href }">
              <div @click="navigate" :href="href" class="cursor-pointer flex items-center">
                <template v-if="title">
                  <img :src="require('@/assets/images/vega-logo/vega_logo_black.png')" class="mr-1 fill-current vega-logo" />
                </template>

                <template v-else>
                  <img :src="require('@/assets/images/vega-logo/vega_logo_black.png')" class="mr-1 fill-current vega-logo" />
                </template>
              </div>
            </template>
          </router-link>
          <!-- /Logo -->

          <!-- Menu Buttons -->
          <div v-if="false">
            <!-- Close Button -->
            <template v-if="showCloseButton">
              <feather-icon icon="XIcon" class="m-0 cursor-pointer" @click="$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)" />
            </template>

            <!-- Toggle Buttons -->
            <template v-else-if="!showCloseButton && !verticalNavMenuItemsMin">
              <feather-icon
                id="btnVNavMenuMinToggler"
                class="mr-0 cursor-pointer"
                :icon="reduce ? 'CircleIcon' : 'DiscIcon'"
                svg-classes="stroke-current text-primary"
                @click="toggleReduce(!reduce)"
              />
            </template>
          </div>
          <!-- /Menu Toggle Buttons -->
        </div>
        <!-- /Header -->

        <!-- Header Shadow -->
        <div class="shadow-bottom" v-show="showShadowBottom" />

        <online-users v-if="isMobile" :isMobile="isMobile" />

        <!-- Menu Items -->
        <component
          :is="scrollbarTag"
          ref="verticalNavMenuPs"
          class="scroll-area-v-nav-menu pt-2"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
          @scroll="psSectionScroll"
          :key="$vs.rtl"
        >
          <template v-for="(item, index) in filteredDashboardNavMenuItemsLocal">
            <!-- Group Header -->
            <span v-if="item.header && !verticalNavMenuItemsMin" class="navigation-header truncate" :key="`header-${index}-${item.slug}`">
              {{ item.header }}
            </span>
            <!-- /Group Header -->

            <template v-else-if="!item.header && (item.disallowedRoles ? !item.disallowedRoles.find((x) => x === activeUserInfo.role) : true)">
              <!-- Nav-Item -->
              <v-nav-menu-item
                v-if="!item.submenu"
                :featherIcon="item.featherIcon"
                :key="`item-${index}-${item.slug}`"
                :index="index"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :iconPack="item.iconPack"
                :target="item.target"
                :isDisabled="disableMenu(item) || disableMenuItems"
                :slug="item.slug"
              >
                <span :id="item.id" v-show="!verticalNavMenuItemsMin" class="truncate">{{ $t(item.name) }} </span>
                <vs-chip class="ml-auto" :color="item.tagColor" v-if="item.tag && (isMouseEnter || !reduce)">{{ item.tag }}</vs-chip>
                <div v-if="item.showNewChip" class="vmenu--newfeature">New</div>
              </v-nav-menu-item>

              <!-- Nav-Group -->
              <template v-else>
                <v-nav-menu-group
                  :key="`group-${index}-${item.slug}`"
                  :openHover="openGroupHover"
                  :group="item"
                  :groupIndex="index"
                  :open="isGroupActive(item)"
                />
              </template>
              <!-- /Nav-Group -->
            </template>
          </template>

          <div class="navigation-header__group-heading">Communication</div>

          <template v-for="(item, index) in filteredCommunicationNavMenuItemsLocal">
            <!-- Group Header -->
            <span v-if="item.header && !verticalNavMenuItemsMin" class="navigation-header truncate" :key="`header-${index}-${item.slug}`">
              {{ item.header }}
            </span>
            <!-- /Group Header -->

            <template v-else-if="!item.header && (item.disallowedRoles ? !item.disallowedRoles.find((x) => x === activeUserInfo.role) : true)">
              <!-- Nav-Item -->
              <v-nav-menu-item
                v-if="!item.submenu"
                :featherIcon="item.featherIcon"
                :key="`item-${index}-${item.slug}`"
                :index="index"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :iconPack="item.iconPack"
                :target="item.target"
                :isDisabled="disableMenu(item) || disableMenuItems"
                :slug="item.slug"
              >
                <span :id="item.id" v-show="!verticalNavMenuItemsMin" class="truncate">{{ $t(item.name) }} </span>
                <vs-chip class="ml-auto" :color="item.tagColor" v-if="item.tag && (isMouseEnter || !reduce)">{{ item.tag }}</vs-chip>
                <div v-if="item.showNewChip" class="vmenu--newfeature">New</div>
              </v-nav-menu-item>

              <!-- Nav-Group -->
              <template v-else>
                <v-nav-menu-group
                  :key="`group-${index}-${item.slug}`"
                  :openHover="openGroupHover"
                  :group="item"
                  :groupIndex="index"
                  :open="isGroupActive(item)"
                />
              </template>
              <!-- /Nav-Group -->
            </template>
          </template>

          <div v-if="activeUserInfo && !['view-only', 'vega-analyze'].includes(activeUserInfo.role)" class="navigation-header__group-heading">
            Configuration
          </div>

          <template v-for="(item, index) in filteredConfigurationAndOtherNavMenuItemsLocal">
            <!-- Group Header -->
            <span v-if="item.header && !verticalNavMenuItemsMin" class="navigation-header truncate" :key="`header-${index}-${item.slug}`">
              {{ item.header }}
            </span>
            <!-- /Group Header -->

            <template v-else-if="!item.header && (item.disallowedRoles ? !item.disallowedRoles.find((x) => x === activeUserInfo.role) : true)">
              <!-- Nav-Item -->
              <v-nav-menu-item
                v-if="!item.submenu"
                :featherIcon="item.featherIcon"
                :key="`item-${index}-${item.slug}`"
                :index="index"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :iconPack="item.iconPack"
                :target="item.target"
                :isDisabled="disableMenu(item) || disableMenuItems"
                :slug="item.slug"
              >
                <span :id="item.id" v-show="!verticalNavMenuItemsMin" class="truncate">{{ $t(item.name) }} </span>
                <vs-chip class="ml-auto" :color="item.tagColor" v-if="item.tag && (isMouseEnter || !reduce)">{{ item.tag }}</vs-chip>
                <div v-if="item.showNewChip" class="vmenu--newfeature">New</div>
              </v-nav-menu-item>

              <!-- Nav-Group -->
              <template v-else>
                <v-nav-menu-group
                  :key="`group-${index}-${item.slug}`"
                  :openHover="openGroupHover"
                  :group="item"
                  :groupIndex="index"
                  :open="isGroupActive(item)"
                />
              </template>
              <!-- /Nav-Group -->
            </template>
          </template>
        </component>
        <!-- /Menu Items -->
      </div>
    </vs-sidebar>

    <!-- Swipe Gesture -->
    <div v-if="!isVerticalNavMenuActive" class="v-nav-menu-swipe-area" v-hammer:swipe="onSwipeAreaSwipe" />
    <!-- /Swipe Gesture -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VNavMenuGroup from './VerticalNavMenuGroup.vue'
import VNavMenuItem from './VerticalNavMenuItem.vue'
import OnlineUsers from '@/layouts/components/navbar/components/OnlineUsers.vue'
import moment from 'moment'

import Logo from '../Logo.vue'

export default {
  name: 'v-nav-menu',
  components: {
    VNavMenuGroup,
    VNavMenuItem,
    VuePerfectScrollbar,
    Logo,
    OnlineUsers
  },
  props: {
    logo: { type: String },
    openGroupHover: { type: Boolean, default: false },
    parent: { type: String },
    reduceNotRebound: { type: Boolean, default: true },
    navMenuItems: { type: Array, required: true },
    title: { type: String },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    clickNotClose: false, // disable close navMenu on outside click
    isMouseEnter: false,
    reduce: false, // determines if navMenu is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    settings: {
      // perfectScrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true
    },
    showShadowBottom: false,
    navMenuItemsLocal: [],
    colorOfUnreadActions: 'rgb(240, 85, 65)',
    baseRoutePageBySlug: 'home',
    communicationRouteListBySlug: ['visitors', 'incoming-requests', 'lead-overview', 'online-meeting'],
    currentBadgeIconValue: 0,
    defaultTitle: `Let's Connect`,
    titleInterval: null
  }),
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      allmeetings: 'meetings',
      leads: 'leads',
      visitors: 'incoming',
      lockedUI: 'lockedUI',
      subscriptionCancelled: 'subscriptionCancelled'
    }),

    filteredDashboardNavMenuItemsLocal() {
      return this.navMenuItemsLocal.filter((item) => this.baseRoutePageBySlug === item.slug)
    },

    filteredCommunicationNavMenuItemsLocal() {
      return this.navMenuItemsLocal.filter((item) => this.communicationRouteListBySlug.includes(item.slug))
    },

    filteredConfigurationAndOtherNavMenuItemsLocal() {
      return this.navMenuItemsLocal.filter((item) => !this.communicationRouteListBySlug.includes(item.slug) && this.baseRoutePageBySlug !== item.slug)
    },

    isGroupActive() {
      return (item) => {
        const path = this.$route.fullPath
        const routeParent = this.$route.meta ? this.$route.meta.parent : undefined
        let open = false

        const func = (item) => {
          if (item.submenu) {
            item.submenu.forEach((item) => {
              if (item.url && (path === item.url || routeParent === item.slug)) {
                open = true
              } else if (item.submenu) {
                func(item)
              }
            })
          }
        }
        func(item)
        return open
      }
    },
    isVerticalNavMenuActive: {
      get() {
        return this.$store.state.isVerticalNavMenuActive
      },
      set(val) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val)
      }
    },
    layoutType() {
      return this.$store.state.mainLayoutType
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton
      },
      set(val) {
        this.$store.commit('TOGGLE_REDUCE_BUTTON', val)
      }
    },
    isVerticalNavMenuReduced() {
      return Boolean(this.reduce && this.reduceButton)
    },
    verticalNavMenuItemsMin() {
      return this.$store.state.verticalNavMenuItemsMin
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    showPartnerProgram() {
      return this.company && typeof this.company.affiliatePartner !== 'undefined'
    },
    disableMenuItems() {
      return (
        !this.activeUserInfo ||
        !this.activeUserInfo.company ||
        (this.company && this.company.name === 'Incomplete Registration') ||
        (this.company && this.company.paymentStatus === 'expired')
      )
    },
    disableMenu() {
      return (item) => {
        let disable = false
        if (
          this.activeUserInfo &&
          this.company &&
          this.company.paymentStatus &&
          (!this.company.expiryDate || (this.company.expiryDate && moment(this.company.expiryDate).format('MM/DD/yyyy') < moment().format('MM/DD/yyyy'))) &&
          (this.company.paymentStatus === 'expired' || this.company.paymentStatus === 'deleted')
        ) {
          if (item.name === 'routes.visitors' || item.name === 'routes.partnerProgram') {
            disable = false
          } else {
            disable = true
          }
        }

        return disable
      }
    },

    locale() {
      return this.activeUserInfo.language ? this.activeUserInfo.language : this.$defaultLanguage
    }
  },
  watch: {
    $route() {
      if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      if (['online-meeting', 'lead-overview'].includes(this.$route.name)) {
        this.navMenuItemsLocal.forEach((item) => {
          if (item.slug === this.$route.name) {
            item.tag = ''
          }
        })
      }
    },
    reduce(val) {
      const verticalNavMenuWidth = val ? 'reduced' : this.$store.state.windowWidth < 1200 ? 'no-nav-menu' : 'default'
      this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

      setTimeout(function () {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    layoutType() {
      this.setVerticalNavMenuWidth()
    },
    reduceButton() {
      this.setVerticalNavMenuWidth()
    },
    windowWidth() {
      this.setVerticalNavMenuWidth()
    },

    async allmeetings() {
      const userData = this.activeUserInfo
      const lastRead = this.getLastReadPages(userData, 'onlineMeeting')

      let countOfUnreadItems = this.allmeetings.length

      countOfUnreadItems = this.allmeetings.filter((meeting) => {
        if (meeting.type === 'online-meeting' && this.activeUserInfo && this.activeUserInfo.uid === meeting.agentId) {
          return false
        }

        if (!meeting.created || !userData.created || !userData.created.seconds) {
          return false
        }

        if (lastRead) {
          return (
            dayjs(meeting.created).isAfter(dayjs(dayjs.unix(userData.created.seconds).tz(this.$defaultTimezone).locale(this.locale))) &&
            dayjs(dayjs.unix(lastRead.seconds).tz(this.$defaultTimezone).locale(this.locale)).isBefore(dayjs(meeting.created))
          )
        }

        return dayjs(meeting.created).isAfter(dayjs(dayjs.unix(userData.created.seconds).tz(this.$defaultTimezone).locale(this.locale)))
      }).length

      this.updateNavMenuTag('online-meeting', countOfUnreadItems)
    },

    async leads() {
      const userData = this.activeUserInfo
      const lastRead = this.getLastReadPages(userData, 'leadOverview')

      let countOfUnreadItems = this.leads.length

      countOfUnreadItems = this.leads.filter((lead) => {
        if (lead.contacted) {
          return false
        }

        /* Show Leads Count only for Targeted Agents (agentsToBeNotified) */
        let agents = []
        if (this.activeUserInfo && this.activeUserInfo.role === 'agent') {
          let _name = this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
          if (this.activeUserInfo.displayName.indexOf('@') > -1) {
            _name = `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
          }
          agents = [{ id: this.activeUserInfo.uid, name: _name }]
        }

        if (lead.agentsToBeNotified && lead.agentsToBeNotified.length > 0) {
          const allIdsExist = agents.some((obj) => lead.agentsToBeNotified.includes(obj.id))
          if (!allIdsExist) {
            return false
          }
        }
        /* Show Leads Count only for Targeted Agents (agentsToBeNotified) */

        if (['string', 'number'].includes(typeof lead.created)) {
          lead.created = dayjs(lead.created).toDate()
        }

        if (lead.created.seconds) {
          lead.created = dayjs.unix(lead.created.seconds).toDate()
        }

        if (!lead.created || !userData.created || !userData.created.seconds) {
          return false
        }

        if (lastRead) {
          return (
            dayjs(lead.created).isAfter(dayjs(dayjs.unix(userData.created.seconds).tz(this.$defaultTimezone).locale(this.locale))) &&
            dayjs(dayjs.unix(lastRead.seconds).tz(this.$defaultTimezone).locale(this.locale)).isBefore(dayjs(lead.created))
          )
        }

        return dayjs(lead.created).isAfter(dayjs(dayjs.unix(userData.created.seconds).tz(this.$defaultTimezone).locale(this.locale)))
      }).length

      this.updateNavMenuTag('lead-overview', countOfUnreadItems)
    },

    visitors() {
      const filteredVisitorList = this.visitors.filter((visitor) => {
        if (visitor.agentRequest && visitor.online && !visitor.connectedAgent && visitor.initiatedBy !== 'agent') {
          return true
        }

        return this.getUnreadMessagesByAllAgents(visitor).length > 0
      })

      const filteredIncomingRequestFromVisitorList = this.visitors.filter((visitor) => {
        if (visitor.agentRequest && visitor.online && !visitor.connectedAgent && visitor.initiatedBy !== 'agent') {
          return true
        }
      })

      const filteredVisitorListLength = filteredVisitorList.length
      const filteredIncomingRequestFromVisitorListLength = filteredIncomingRequestFromVisitorList.length

      if (filteredIncomingRequestFromVisitorListLength > 0) {
        this.blinkTitle(`(${filteredIncomingRequestFromVisitorListLength}) ${this.$t('buttonAndHints.incomingRequest')}`, 500)
      } else if (filteredVisitorListLength > 0) {
        this.blinkTitle(`(${filteredVisitorListLength}) ${this.$t('buttonAndHints.newMessage')}`)
      } else {
        this.resetTitle()
      }

      this.updateNavMenuTag('incoming-requests', filteredVisitorListLength)

      if (filteredVisitorListLength !== this.currentBadgeIconValue) {
        if (filteredVisitorListLength > 0) {
          this.currentBadgeIconValue = filteredVisitorListLength

          this.updateBadge(filteredVisitorListLength)
        } else {
          this.currentBadgeIconValue = 0

          this.clearBadge()
          this.resetTitle()
        }
      }
    }
  },
  methods: {
    getLastReadPages(data, slug) {
      if (data.lastReadPages && data.lastReadPages[slug]) {
        return data.lastReadPages[slug]
      }

      return null
    },

    getUnreadMessagesByAllAgents(visitor) {
      if (!visitor || visitor.connectedAgentId) {
        return []
      } else if (visitor.chats) {
        const lastMessage = visitor.chats[visitor.chats.length - 1]

        if (lastMessage && (lastMessage.sender === 'agent' || (lastMessage.agentInfo && lastMessage.agentInfo.connectedAgent))) {
          return []
        }
      }

      const unreadMessagesByAllAgents = []

      let lastReadTime = null
      const isConnectedAgents = visitor.connectedAgents && visitor.connectedAgents.length

      if (isConnectedAgents) {
        const lastConnectedAgent = [...visitor.connectedAgents].reverse().find((item) => item.type === 'agent-joined')

        if (lastConnectedAgent && lastConnectedAgent.sessionEndTime) {
          lastReadTime = dayjs(lastConnectedAgent.sessionEndTime.seconds * 1000)
            .tz(this.$defaultTimezone)
            .diff('2021-01-01')
        }
      }

      if (visitor.lastReadChatsAgentInfo) {
        visitor.lastReadChatsAgentInfo.forEach((item) => {
          if (!lastReadTime || item.lastRead > lastReadTime) {
            lastReadTime = item.lastRead
          }
        })
      }

      if (visitor.chats) {
        visitor.chats.forEach((item) => {
          if (item.sender === 'visitor' && item.chatTime > lastReadTime) {
            unreadMessagesByAllAgents.push(item)
          }
        })
      }

      return unreadMessagesByAllAgents
    },

    updateNavMenuTag(slug, unreadItems) {
      this.navMenuItemsLocal = this.navMenuItemsLocal.map((item) => {
        const copyItem = { ...item }

        if (copyItem.slug === slug) {
          copyItem.tag = unreadItems || ''
          copyItem.tagColor = this.colorOfUnreadActions
        }

        return copyItem
      })
    },

    menuItemsUpdated() {
      const isFinancial = this.activeUserInfo && this.activeUserInfo.role === 'financial'
      let clone = this.navMenuItems.slice()

      for (const [index, item] of this.navMenuItems.entries()) {
        if (item.name === 'routes.partnerProgram' && !this.showPartnerProgram) {
          const idx = clone.findIndex((x) => x.name === 'routes.partnerProgram')
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
        if (item.name === 'routes.promoCodes' && this.activeUserInfo && !this.activeUserInfo.superadmin) {
          const idx = clone.findIndex((x) => x.name === 'routes.promoCodes')
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
        if (item.name === 'routes.companies' && this.activeUserInfo && !this.activeUserInfo.superadmin) {
          const idx = clone.findIndex((x) => x.name === 'routes.companies')
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
        if (item.name === 'routes.accounts' && this.activeUserInfo && !this.activeUserInfo.superadmin) {
          const idx = clone.findIndex((x) => x.name === 'routes.accounts')
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
        if (item.name === 'routes.inAppMessaging' && this.activeUserInfo && !this.activeUserInfo.superadmin) {
          const idx = clone.findIndex((x) => x.name === 'routes.inAppMessaging')
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
        if (item.name === 'routes.invoices' && this.activeUserInfo && !this.activeUserInfo.superadmin && !isFinancial) {
          const idx = clone.findIndex((x) => x.name === 'routes.invoices')
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
        if (isFinancial) {
          clone = clone.filter((el) => el.name === 'routes.invoices')
        }

        if (item.header && item.items.length && (index || 1)) {
          const i = clone.findIndex((ix) => ix.header === item.header)
          for (const [subIndex, subItem] of item.items.entries()) {
            clone.splice(i + 1 + subIndex, 0, subItem)
          }
        }

        if (['routes.visitors', 'routes.translations'].includes(item.name) && this.isMobile) {
          const idx = clone.findIndex((x) => x.name === item.name)
          if (idx > -1) {
            clone.splice(idx, 1)
          }
        }
      }

      return clone
    },

    onMenuSwipe(event) {
      if (event.direction === 4 && this.$vs.rtl) {
        // Swipe Right
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
      } else if (event.direction === 2 && !this.$vs.rtl) {
        // Swipe Left
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
      }
    },
    onSwipeAreaSwipe(event) {
      if (event.direction === 4 && !this.$vs.rtl) {
        // Swipe Right
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      } else if (event.direction === 2 && this.$vs.rtl) {
        // Swipe Left
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      }
    },
    psSectionScroll() {
      const scroll_el = this.$refs.verticalNavMenuPs.$el || this.$refs.verticalNavMenuPs
      this.showShadowBottom = scroll_el.scrollTop > 0
    },
    mouseEnter() {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
      this.isMouseEnter = true
    },
    mouseLeave() {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true)
      this.isMouseEnter = false
    },
    setVerticalNavMenuWidth() {
      if (this.windowWidth > 1200) {
        if (this.layoutType === 'vertical') {
          // Set reduce
          this.reduce = !!this.reduceButton

          // Open NavMenu
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

          // Set Menu Items Only Icon Mode
          const verticalNavMenuItemsMin = !!(this.reduceButton && !this.isMouseEnter)
          this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

          // Menu Action buttons
          this.clickNotClose = true
          this.showCloseButton = false

          const verticalNavMenuWidth = this.isVerticalNavMenuReduced ? 'reduced' : 'default'
          this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

          return
        }
      }

      // Close NavMenu
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      // Reduce button
      if (this.reduceButton) this.reduce = false

      // Menu Action buttons
      this.showCloseButton = true
      this.clickNotClose = false

      // Update NavMenu Width
      this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      // Remove Only Icon in Menu
      this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

      // if(this.layoutType === 'vertical' || (this.layoutType === 'horizontal' && this.windowWidth < 1200))
      // if (this.windowWidth < 1200) {

      //   // Close NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      //   // Reduce button
      //   if (this.reduceButton) this.reduce = false

      //   // Menu Action buttons
      //   this.showCloseButton = true
      //   this.clickNotClose   = false

      //   // Update NavMenu Width
      //   this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      //   // Remove Only Icon in Menu
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

      // } else {

      //   // Set reduce
      //   this.reduce = this.reduceButton ? true : false

      //   // Open NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

      //   // Set Menu Items Only Icon Mode
      //   const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

      //   // Menu Action buttons
      //   this.clickNotClose   = true
      //   this.showCloseButton = false

      //   const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? "reduced" : "default"
      //   this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)
      // }
    },
    toggleReduce(val) {
      this.reduceButton = val
      this.setVerticalNavMenuWidth()
    },

    async updateBadge(unreadCount) {
      if ('setAppBadge' in navigator) {
        try {
          await navigator.setAppBadge(unreadCount)
        } catch (error) {
          console.error('Failed to update badge:', error)
        }
      }
    },

    async clearBadge() {
      if ('clearAppBadge' in navigator) {
        try {
          await navigator.clearAppBadge()
        } catch (error) {
          console.error('Failed to clear badge:', error)
        }
      }
    },

    blinkTitle(title, interval = 1000) {
      this.resetTitle()

      let showCustomTitle = true

      this.titleInterval = setInterval(() => {
        if (document && document.title) {
          document.title = showCustomTitle ? title : this.defaultTitle
        }

        showCustomTitle = !showCustomTitle
      }, interval)
    },

    resetTitle() {
      if (this.titleInterval) {
        clearInterval(this.titleInterval)
        this.titleInterval = null
      }

      if (document && document.title) {
        document.title = this.defaultTitle
      }
    }
  },
  mounted() {
    this.setVerticalNavMenuWidth()

    this.navMenuItemsLocal = this.menuItemsUpdated()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/components/verticalNavMenu.scss';

._locked {
  filter: blur(2px);
  pointer-events: none;
}

.vmenu {
  &--newfeature {
    width: 45px;
    height: 17px;
    background: #eef8ff;
    border-radius: 30px;
    margin-left: 26px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 14px;
    color: #53a5f2;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.vega-logo {
  max-width: 200px;
}

.v-nav-menu {
  .navigation-header {
    &__group-heading {
      margin-top: 24px;
      margin-bottom: 4px;
      padding: 0px 30px;
      font-size: 1.1rem;
      text-transform: uppercase;
    }
  }

  .feather-icon {
    .feather {
      width: 18px;
      height: 18px;
    }
  }

  .vs-sidebar--item {
    a {
      padding: 8px 15px;

      span {
        font-size: 14px;
      }
    }
  }

  .con-vs-chip {
    margin-bottom: -1px;
    min-width: 18px;
    min-height: 18px;
  }
}
</style>
